<style>
table.header--apexpert thead tr th:nth-child(1) {
  width: 60px;
}
table.header--apexpert thead tr th:nth-child(2) {
  width: 90px;
}
table.header--apexpert thead tr th:nth-child(3) {
  width: 90px;
}
table.header--apexpert thead tr th:nth-child(4) {
  width: 44px;
}
table.header--apexpert thead tr th:nth-child(5) {
  width: 110px;
}
table.header--apexpert thead tr th:nth-child(6) {
  width: 77px;
}
table.header--apexpert thead tr th:nth-child(7) {
  width: 66px;
}
table.header--apexpert thead tr th:nth-child(8) {
  width: 66px;
}
table.header--apexpert thead tr th:nth-child(9) {
  width: 66px;
}
table.header--apexpert thead tr th:nth-child(10) {
  width: 66px;
}
table.header--apexpert thead tr th:nth-child(11) {
  width: 66px;
}
table.header--apexpert thead tr th:nth-child(12) {
  width: 66px;
}
</style>

<template>
  <main class="main2">
    <div
      v-if="apexpert === undefined || apexpert.clients === null"
      class="container loader-wrapper"
    >
      <div class="row">
        <div class="col-xs-12 text-center">
          <p class="loading2">{{ $t("default.loading") }}</p>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container">
      <div class="row">
        <div class="col-xs-12 col-md-5">
          <input
            type="text"
            class="form-control matb20"
            v-model="search"
            :placeholder="$t('tables.search')"
          />
        </div>
        <div class="col-xs-12 col-md-7">
          <div class="flexWrap">
            <div
              @click="download"
              class="abtn--c4"
              style="margin-right: 16px; cursor: pointer"
            >
              {{ $t("default.download") }}
            </div>
            <pagination
              v-model="page"
              :total-page="pages"
              size="md"
              align="right"
              boundary-links
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <Table
            class="rwdtable header--apexpert"
            style="width: 100%"
            v-if="apexpert"
            :items="apexpert"
            :headers="headers"
            :fields="fields"
            :search="search"
            :page.sync="page"
            :itemsPerPage="itemsPerPage"
            :pages.sync="pages"
            @tr:clicked="OPEN_POPUP_CLIENT($event.client_ix)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <pagination
            v-model="page"
            :total-page="pages"
            size="md"
            align="right"
            boundary-links
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Table from "../components/Table";
import { FETCH_APEXPERT } from "../store/actions.type";
import { OPEN_POPUP_CLIENT } from "../store/mutations.type";
import xlsx from "json-as-xlsx";

export default {
  components: {
    Table,
  },
  data() {
    return {
      fields: [
        "nip",
        "client_ix",
        "client_branch",
        "client_symbol",
        "client_ph",
        "client_target",
        "clinet_buys_all",
        "clinet_points_all",
        "points",
        "client_percent",
        "client_returns_percent",
        "statute_accepted_at",
      ],
      search: "",
      itemsPerPage: 30,
      page: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapGetters(["apexpert"]),
    headers() {
      return [
        this.$i18n.t("tables.NIP"),
        this.$i18n.t("tables.customerNumber"),
        this.$i18n.t("tables.branch"),
        this.$i18n.t("tables.symbol"),
        this.$i18n.t("tables.ph"),
        this.$i18n.t("tables.target"),
        this.$i18n.t("tables.buysAll"),
        this.$i18n.t("tables.pointsAll"),
        this.$i18n.t("tables.points"),
        this.$i18n.t("tables.percent"),
        this.$i18n.t("tables.client_returns_percent"),
        this.$i18n.t("tables.statute_accepted_at"),
      ];
    },
  },
  created() {
    this.$store.dispatch(FETCH_APEXPERT);
  },
  methods: {
    ...mapMutations([OPEN_POPUP_CLIENT]),
    download() {
      let data = [
        {
          sheet: "",
          columns: [
            { label: this.$i18n.t("tables.NIP"), value: "nip" },
            {
              label: this.$i18n.t("tables.customerNumber"),
              value: "client_ix",
            },
            { label: this.$i18n.t("tables.branch"), value: "client_branch" },
            { label: this.$i18n.t("tables.symbol"), value: "client_symbol" },
            { label: this.$i18n.t("tables.ph"), value: "client_ph" },
            {
              label: this.$i18n.t("tables.target"),
              value: "client_target",
              // eslint-disable-next-line no-useless-escape
              format: "#,##0.00\ [$zł-415]",
            },
            {
              label: this.$i18n.t("tables.buysAll"),
              value: "clinet_buys_all",
              // eslint-disable-next-line no-useless-escape
              format: "#,##0.00\ [$zł-415]",
            },
            {
              label: this.$i18n.t("tables.pointsAll"),
              value: "clinet_points_all",
            },
            { label: this.$i18n.t("tables.points"), value: "points" },
            { label: this.$i18n.t("tables.percent"), value: "client_percent" },
            {
              label: this.$i18n.t("tables.client_returns_percent"),
              value: "client_returns_percent",
            },
            {
              label: this.$i18n.t("tables.statute_accepted_at"),
              value: "statute_accepted_at",
            },
          ],
          content: this.apexpert,
        },
      ];

      let settings = {
        fileName: `AP_EXPERTP_12`,
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
      };
      xlsx(data, settings);
    },
  },
};
</script>
